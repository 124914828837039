export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyC5i1KsjXohOcfCah0rUPXgOZi9NyWTM2s',
    authDomain: 'awo-care.firebaseapp.com',
    projectId: 'awo-care',
    storageBucket: 'awo-care.appspot.com',
    messagingSenderId: '916102094521',
    appId: '1:916102094521:web:b5bc5be388c4f7fa8ac568',
    measurementId: 'G-ML9GL99F71',
  },
  useEmulators: false,
  googleMapsAPIKey: 'AIzaSyAwR2CCpirGqq1gyvTFSkovadg8VKHFY8c',
  recaptchaSiteKey: '6Lf3MIgqAAAAAIDDzXOLvTbYif3a7HiSp0GbJyOX',
  microsoftTenantId: '8ef6f487-81f8-449b-85ab-5ec65b8ad8de',
  companyDomain: 'awo-hs.org',
};
