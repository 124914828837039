// base-auth.service.ts
import { Injectable, inject } from '@angular/core';
import {
  Auth,
  User,
  AuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  applyActionCode,
} from '@angular/fire/auth';
import { Functions } from '@angular/fire/functions';
import {
  Firestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  Timestamp,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BaseUser } from '@awo-care/shared-core';

@Injectable()
export abstract class BaseAuthService {
  protected auth = inject(Auth);
  protected firestore = inject(Firestore);
  protected functions = inject(Functions);
  protected router = inject(Router);

  protected abstract userCollection: string;

  protected createBaseUserData(user: User): BaseUser {
    return {
      id: user.uid,
      email: user.email || '',
      emailVerified: user.emailVerified,
      isActive: true,
      provider: user.providerData[0]?.providerId || 'unknown',
      role: 'user',
      lastSignIn: Timestamp.now(),
      createdOn: Timestamp.now(),
    };
  }

  protected async processLogin(user: User): Promise<void> {
    await this.addUser(user);
  }

  protected async loginWithProvider(provider: AuthProvider): Promise<User> {
    const result = await signInWithPopup(this.auth, provider);
    await this.processLogin(result.user);
    return result.user;
  }

  async addUser(user: User) {
    const userData = this.createBaseUserData(user);
    return this.addFirebaseUser(user.uid, userData);
  }

  protected async addFirebaseUser<T extends BaseUser>(
    uid: string,
    userData: T
  ): Promise<T> {
    try {
      const userRef = doc(this.firestore, this.userCollection, uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        await setDoc(userRef, userData);
        return userData;
      }

      // Update lastSignIn if user exists
      await updateDoc(userRef, { lastSignIn: Timestamp.now() });
      return userDoc.data() as T;
    } catch (error) {
      const err = error as Error;
      throw new Error(`Failed to add user: ${err.message}`);
    }
  }

  async updateUser(uid: string, data: Partial<BaseUser>): Promise<void> {
    try {
      const userRef = doc(this.firestore, this.userCollection, uid);
      await updateDoc(userRef, data);
    } catch (error) {
      const err = error as Error;
      throw new Error(`Failed to update user: ${err.message}`);
    }
  }

  async sendPasswordReset(email: string) {
    const baseUrl = window.location.origin;
    const actionCodeSettings = {
      url: `${baseUrl}/auth/verify`,
      handleCodeInApp: true,
    };

    try {
      await sendPasswordResetEmail(this.auth, email, actionCodeSettings);
      return true;
    } catch (error) {
      const err = error as Error;
      throw new Error(`Failed to send password reset: ${err.message}`);
    }
  }

  async emailLogin(email: string, password: string): Promise<User> {
    const userCredential = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    await this.processLogin(userCredential.user);
    return userCredential.user;
  }

  async createEmailAccount(email: string, password: string) {
    return await createUserWithEmailAndPassword(this.auth, email, password);
  }

  abstract signOut(): Promise<void>;

  async sendVerificationEmail(user: User): Promise<void> {
    if (!user) throw new Error('No user provided');

    const baseUrl = window.location.origin;
    const actionCodeSettings = {
      url: `${baseUrl}/auth/verify`,
      handleCodeInApp: true,
    };

    try {
      const currentUser = this.auth.currentUser;
      if (!currentUser) throw new Error('No authenticated user');

      await sendEmailVerification(currentUser, actionCodeSettings);
      console.log('Verification email has been sent');
    } catch (error) {
      console.error('Error sending verification email:', error);
      throw error;
    }
  }

  async handleVerifyEmail(actionCode: string): Promise<void> {
    try {
      await applyActionCode(this.auth, actionCode);
      if (this.auth.currentUser) {
        await this.addUser(this.auth.currentUser);
        return await this.updateUser(this.auth.currentUser.uid, {
          emailVerified: true,
        });
      } else {
        throw new Error('No user found');
      }
    } catch (error) {
      await this.router.navigateByUrl('auth/login');
      throw error;
    }
  }
}
